
import { byteLength } from 'byte-length';
import dialogShell from '@/components/common/dialog-shell/index.vue';
import deviceInfoOnceautopDialog from '@/components/view/pm/device-info-onceautop-dialog';
import HttpRequest from '@/util/axios.config';
import {
    defineComponent, ref, Ref, PropType,
    watch, computed,
    onBeforeUnmount
} from 'vue';
import { ElMessageBox } from 'element-plus';
import Notice from '@/util/notice.base';
import JumpPage from '@/components/view/common/jump-page/index.vue';
import { SettingFormData, ConnectTypeListData, SettingFormKey } from './all.type';
import {
    getSetConfigApiUrl, getRebootApiUrl, getRemoteApiUrl, getResetApiUrl
} from './utils';
import loadingAnimationDialog from '../loading-animation-dialog';

const connectType: Array<ConnectTypeListData> = [
    {
        label: 'UDP',
        value: '0'
    }, {
        label: 'TCP',
        value: '1'
    }, {
        label: 'TLS',
        value: '2'
    }
];
export default defineComponent({
    components: {
        dialogShell,
        deviceInfoOnceautopDialog,
        JumpPage,
        loadingAnimationDialog
    },
    props: {
        initForm: {
            type: Object as PropType<SettingFormData>,
            required: true
        },
        identity: {
            type: String
        },
        projectType: {
            type: String,
            default: ''
        }
    },
    setup(props, { emit }) {
        const deviceSettingFormRef: Ref<any> = ref();
        const formData = ref<SettingFormData>({
            MAC: '',
            SipType: '0',
            Config: ''
        });

        const isConfigToLong = computed(() => byteLength(formData.value.Config) > 2048);

        watch(computed(() => props.initForm), () => {
            Object.keys(formData.value).forEach((key) => {
                const ele = key as SettingFormKey;
                if (key === 'SipType') {
                    connectType.forEach((item) => {
                        if (item.label === props.initForm.SipType) {
                            formData.value.SipType = item.value;
                        }
                    });
                } else {
                    formData.value[ele] = props.initForm[ele];
                }
            });
        }, {
            immediate: true
        });

        const close = () => {
            emit('close');
        };
        const submit = () => {
            deviceSettingFormRef.value.validate((valid: boolean) => {
                if (valid) {
                    const url = getSetConfigApiUrl(props.projectType);
                    HttpRequest.post(url, formData.value, (res: any) => {
                        if (props.identity === 'super') {
                            const deviceDetail = JSON.parse(localStorage.getItem('detail') as string);
                            deviceDetail.SipType = ['UDP', 'TCP', 'TLS'][Number(formData.value.SipType)];
                            deviceDetail.Config = formData.value.Config;
                            localStorage.setItem('detail', JSON.stringify(deviceDetail));
                        }
                        emit('success');
                        close();
                    });
                }
            });
        };

        const reboot = () => {
            Notice.messageBox('confirm', WordList.ProperAllTextRebootMsg,
                WordList.ListInHtmlReboot, 'warning')(() => {
                    const url = getRebootApiUrl(props.projectType);
                    HttpRequest.post(url, formData.value, [() => {
                        // 编译通过
                    }, false]);
                });
        };

        // 重置设备
        const reset = () => {
            Notice.messageBox(
                'confirm',
                WordList.ResetMsg,
                WordList.Reset,
                'warning'
            )(() => {
                const url = getResetApiUrl(props.projectType);
                HttpRequest.post(url, {
                    MAC: formData.value.MAC
                }, [(res: any) => {
                    ElMessageBox.alert(res.msg, '', {
                        confirmButtonText: WordList.TabelFootSure,
                        type: 'success'
                    });
                }, false]);
            });
        };

        const isShowJumpPage = ref(false);
        const remoteUrl = ref('');
        const accessId = ref('');
        const isLoading = ref(false);
        const jumpUrl = ref('');

        const isShowLoadingDialog = ref(false);

        let timer: NodeJS.Timer | null = null;
        let setTimeoutTimer: NodeJS.Timer;
        function clearTimer() {
            clearInterval(timer as NodeJS.Timer);
            timer = null;
        }

        function jumpToPage() {
            HttpRequest.get('v3/web/common/device/remoteStatus', [{
                AccessId: accessId.value
            }, false], (res: { status: number }) => {
                if (res.status === 1) {
                    isShowLoadingDialog.value = false;
                    const newPage = window.open(`${remoteUrl.value}`);
                    if (newPage === null) {
                        jumpUrl.value = remoteUrl.value;
                        isShowJumpPage.value = true;
                    }
                    clearTimer();
                    isLoading.value = false;
                } else if (res.status === 0) {
                    if (!timer) {
                        timer = setInterval(() => {
                            jumpToPage();
                        }, 3000);
                    }
                } else {
                    clearTimer();
                    isShowLoadingDialog.value = false;
                    clearTimeout(setTimeoutTimer);
                    isLoading.value = false;
                    ElMessageBox.alert(
                        WordList.NetworkErrorPleaseTryAgain,
                        WordList.TabelDeleteTitle,
                        {
                            type: 'error',
                            confirmButtonText: WordList.TabelFootSure
                        }
                    );
                }
            }, (error: string) => {
                isShowLoadingDialog.value = false;
                clearTimer();
                clearTimeout(setTimeoutTimer);
                isLoading.value = false;
            });
        }

        const remote = () => {
            remoteUrl.value = '';
            accessId.value = '';
            isLoading.value = true;
            clearTimer();
            // 30秒连接不上
            setTimeoutTimer = setTimeout(() => {
                isShowLoadingDialog.value = false;
                if (isLoading.value) {
                    ElMessageBox.alert(
                        WordList.ProcessingTimeout,
                        WordList.TabelDeleteTitle,
                        {
                            type: 'error',
                            confirmButtonText: WordList.TabelFootSure
                        }
                    );
                    clearTimer();
                }
            }, 60000);
            isShowLoadingDialog.value = true;
            const url = getRemoteApiUrl(props.projectType);
            HttpRequest.post(url, {
                MAC: formData.value.MAC
            }, [(res: any) => {
                accessId.value = res.accessId;
                remoteUrl.value = res.url;
                clearTimer();
                jumpToPage();
            }, false], (error: string) => {
                isShowLoadingDialog.value = false;
                clearTimeout(setTimeoutTimer);
                clearTimer();
                isLoading.value = false;
            });
        };

        const showOnceDialog = ref(false);
        const onceAutop = () => {
            showOnceDialog.value = true;
        };

        onBeforeUnmount(() => {
            clearTimer();
            if (setTimeoutTimer) {
                clearTimeout(setTimeoutTimer);
            }
            isLoading.value = false;
        });

        function closeLoadingDialog() {
            isShowLoadingDialog.value = false;
            clearTimer();
            clearTimeout(setTimeoutTimer);
            isLoading.value = false;
        }

        return {
            formData,
            isConfigToLong,
            submit,
            close,
            deviceSettingFormRef,
            connectType,
            reboot,
            reset,
            remote,
            onceAutop,
            showOnceDialog,
            isShowJumpPage,
            jumpUrl,
            isShowLoadingDialog,
            closeLoadingDialog
        };
    }
});

